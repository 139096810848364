<template>
  <section
    v-loading="loading"
    :element-loading-text="loadingText"
    style="overflow-y: hidden !important"
    v-if="this.checkUrlType() == 'SignIn'"
  >
    <el-row class="row">
      <el-col
        :xs="{ span: 4 }"
        :sm="{ span: 3 }"
        :md="{ span: 2 }"
        :lg="{ span: 2 }"
        :xl="{ span: 1 }"
      >
        <el-button @click="goBack">
          <i class="el-icon-arrow-left el-icon-left"></i>
        </el-button>
      </el-col>
      <el-col
        :xs="{ span: 13, offset: 2 }"
        :sm="{ span: 13 }"
        :md="{ span: 8 }"
        :lg="{ span: 8 }"
        :xl="{ span: 8 }"
      >
        <el-input
          v-model="customDashboardData.name"
          placeholder="Enter dashboard name"
          class="title-input"
        ></el-input>
      </el-col>
      <el-col
        :xs="{ span: 12 }"
        :sm="{ span: 4, offset: 8 }"
        :md="{ span: 4, offset: 9 }"
        :lg="{ span: 4, offset: 9 }"
        :xl="{ span: 4, offset: 9 }"
      >
        <div style="display: flex">
          <p>Allow change order</p>
          <el-switch
            v-model="customDashboardData.allowUserChangeOrder"
            class="ml-1"
          ></el-switch>
        </div>
      </el-col>
      <el-col
        :xs="{ span: 1, offset: 2 }"
        :sm="{ span: 1, offset: 0 }"
        :md="{ span: 1, offset: 0 }"
        :lg="{ span: 1, offset: 0 }"
        :xl="{ span: 1, offset: 0 }"
      >
        <el-button type="primary" @click="saveDashboard">Save</el-button>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="4" v-if="getIsOpen">
        <div class="left-side-menu">
          <el-row type="flex" justify="center">
            <el-col :xl="24" :lg="24" :md="24">
              <div class="side-menu">
                <el-scrollbar
                  wrap-style="max-height:  calc(100vh - 250px); padding-right: 10px;"
                >
                  <div>
                    <h4>Component Type</h4>
                    <el-select
                      v-model="selectedComponentType"
                      no-data-text="No components available"
                      filterable
                      default-first-option
                      class="mb-1"
                      @change="getComponentInfo"
                    >
                      <el-option
                        v-for="(type, index) of componentTypes"
                        :value="type.value"
                        :key="type.value + index"
                        :label="type.label"
                      >
                        <span style="float: left">{{ type.label }}</span>
                      </el-option>
                    </el-select>
                    <el-checkbox
                      v-if="selectedComponentType != 'LOGINS' && selectedComponentType != 'ENTITY_VIEW'"
                      v-model="customDashboardData.includeGlobalDateFilter"
                      class="mb-1"
                      >Include global date filter</el-checkbox
                    >
                    <div v-if="selectedComponentType == 'CALENDAR'">
                      <h4>Entities</h4>
                      <el-select
                        v-model="selectedCalendarEntities"
                        no-data-text="No Entities available"
                        filterable
                        default-first-option
                        @change="getEntityDateFields"
                        multiple
                        collapse-tags
                      >
                        <el-option
                          v-for="(entity, index) of getAllEntitiesData"
                          :value="entity._id"
                          :key="index"
                          :label="entity.name"
                        >
                          <span style="float: left">{{ entity.name }}</span>
                        </el-option>
                      </el-select>
                    </div>
                    <div v-else-if="selectedComponentType == 'LOGINS'">
                      <h4>Entities</h4>
                      <el-select
                        v-model="selectedLoginEntity"
                        no-data-text="No Entities available"
                        @change="getLoginEntityDateFields"
                        clearable
                        :disabled="isEntitySelectDisabled"
                      >
                        <el-option
                          v-for="(entity, index) of getAllEntitiesData"
                          :value="entity._id"
                          :key="index"
                          :label="entity.name"
                        >
                          <span style="float: left">{{ entity.name }}</span>
                        </el-option>
                      </el-select>
                      <div v-if="isEntityDashboard && !selectedEntityData">
                        <h4>Relational entities</h4>
                        <el-select
                          placeholder="Relational Entity"
                          v-model="selectedEntityRelationalData"
                          @change="getLoginEntityDateFields"
                          clearable
                          :disabled="isRelationalSelectDisabled"
                        >
                          <el-option
                            v-for="(op, i) in getRelationshipEntities"
                            :key="op._id + i"
                            :value="op._id"
                            :label="op.name"
                            >{{ "My " + op.name }}</el-option
                          >
                        </el-select>
                      </div>
                    </div>
                    <div v-else-if="selectedComponentType == 'ENTITY_VIEW'">
                      <h4>Entity Views</h4>
                      <el-select v-model="selectedEntityView" clearable filterable>
                        <el-option v-for="(view, index) of getAllEntityViews.data" :key="index" :value="view._id" :label=view.name>
                        </el-option>
                      </el-select>
                    </div>
                    <div v-else-if="!selectedEntityRelationalData">
                      <h4>Entities</h4>
                      <el-select
                        v-model="selectedEntityData"
                        no-data-text="No Entities available"
                        filterable
                        default-first-option
                        @change="getComponentInfo"
                        clearable
                      >
                        <el-option
                          v-for="(entity, index) of selectedComponentType ==
                          'CUSTOM_FILTER'
                            ? getCurrentEntitiesData
                            : getAllEntitiesData"
                          :value="entity._id"
                          :key="index"
                          :label="entity.name"
                        >
                          <span style="float: left">{{ entity.name }}</span>
                        </el-option>
                      </el-select>
                    </div>
                    <div
                      v-if="
                        isEntityDashboard &&
                        !selectedEntityData &&
                        selectedComponentType != 'CALENDAR' &&
                        selectedComponentType != 'LOGINS' &&
                        selectedComponentType != 'ENTITY_VIEW'
                      "
                    >
                      <h4>Relational entities</h4>
                      <el-select
                        placeholder="Relational Entity"
                        v-model="selectedEntityRelationalData"
                        @change="getComponentInfo"
                        clearable
                      >
                        <el-option
                          v-for="(op, i) in getRelationshipEntities"
                          :key="op._id + i"
                          :value="op._id"
                          :label="op.name"
                          >{{ "My " + op.name }}</el-option
                        >
                      </el-select>
                    </div>
                    <div
                      v-if="
                        isEntityDashboard &&
                        !selectedEntityData &&
                        selectedComponentType == 'CALENDAR'
                      "
                    >
                      <h4>Relational entities</h4>
                      <el-checkbox
                        v-if="
                          selectedCalendarRelationEntities &&
                          selectedCalendarRelationEntities.length
                        "
                        v-model="allowOtherShowData"
                      >
                        Allow others in filter
                      </el-checkbox>
                      <el-select
                        placeholder="Relational Entity"
                        v-model="selectedCalendarRelationEntities"
                        clearable
                        @change="getEntityDateFields"
                        multiple
                      >
                        <el-option
                          v-for="(op, i) in getRelationshipEntities"
                          :key="op._id + i"
                          :value="op._id"
                          :label="op.name"
                          >{{ "My " + op.name }}</el-option
                        >
                      </el-select>
                    </div>
                    <div
                      class="mt-1"
                      v-if="
                        selectedComponentType == 'TABLE' ||
                        selectedComponentType == 'CARD'
                      "
                    >
                      <el-select
                        placeholder="Group by"
                        v-model="tableFieldGroupBy"
                        v-if="
                          selectedComponentType == 'TABLE' && selectedEntityData
                        "
                        clearable
                      >
                        <el-option
                          v-for="(field, index) of getTemplateFields.filter(
                            (e) => e.input_type != 'DATE'
                          )"
                          :value="field.key"
                          :key="field.key + index"
                          :label="`${field.template_name} - ${field.label} `"
                        >
                          <span
                            >{{ field.template_name }} - {{ field.label }}</span
                          >
                        </el-option>
                      </el-select>
                      <el-select
                        placeholder="Sub group by"
                        v-model="tableFieldSubGroupBy"
                        v-if="
                          selectedComponentType == 'TABLE' && tableFieldGroupBy
                        "
                        clearable
                        @change="addSubGroupCount"
                      >
                        <el-option
                          v-for="(field, index) of getTemplateFields.filter(
                            (e) =>
                              e.input_type != 'MULTI_SELECT' &&
                              e.input_type != 'DATE' &&
                              e.key != tableFieldGroupBy
                          )"
                          :value="field.key"
                          :key="field.key + index"
                          :label="`${field.template_name} - ${field.label} `"
                        >
                          <span
                            >{{ field.template_name }} - {{ field.label }}</span
                          >
                        </el-option>
                      </el-select>
                      <el-select
                        placeholder="Select options to group"
                        multiple
                        v-model="tableFieldGroupBySelectedOptions"
                        v-if="tableFieldSubGroupBy"
                        :clearable="true"
                        class="mt-1"
                        collapse-tags
                      >
                        <template
                          v-if="checkFieldType(tableFieldSubGroupBy, 'ENTITY')"
                        >
                          <el-option
                            v-for="(data, index) of groupByFieldEntityValues"
                            :key="index + 'entity' + data._id"
                            :value="data._id"
                            :label="getLabel(data, index, groupByFieldEntity)"
                          >
                            <span>{{ getLabel(data, index, groupByFieldEntity) }}</span>
                          </el-option>
                        </template>
                        <template v-else>
                          <el-option
                            v-for="(
                              option, index
                            ) of pieChartFieldGroupByOptions"
                            :value="option"
                            :key="index"
                            :label="option"
                          >
                            <span>{{ option }}</span>
                          </el-option>
                        </template>
                      </el-select>
                      <div v-if="tableFieldGroupBy" class="mt-1">
                        <el-tag
                          v-for="(field, index) in tableFieldGroupByFields"
                          :key="index + 'name'"
                          :closable="
                            !tableFieldGroupByFields ||
                            !tableFieldGroupByFields.length ||
                            (tableFieldGroupByFields &&
                              tableFieldGroupByFields.length == 1)
                              ? false
                              : true
                          "
                          :type="
                            field && field.sub_group ? 'success' : 'primary'
                          "
                          style="margin-right: 5px; margin-top: 5px"
                          >{{ field.name }}</el-tag
                        >
                        <el-link
                          icon="el-icon-circle-plus-outline"
                          :underline="false"
                          type="primary"
                          style="margin-left: 3px"
                          @click="openTableColums"
                        ></el-link>
                      </div>
                      <h4 class="mt-2">Filters</h4>
                      <el-input
                        v-model="filterSearchString"
                        placeholder="Search"
                        prefix-icon="el-icon-search"
                        @input="searchFilters()"
                      ></el-input>
                      <el-scrollbar wrap-style="max-height: 300px;">
                        <el-checkbox-group
                          v-model="selectedFilter"
                          class="float-left checkboxOrder"
                          v-if="
                            selectedEntityData || selectedEntityRelationalData
                          "
                        >
                          <el-checkbox
                            v-for="(filter, index) of filters"
                            :key="index"
                            class="mt-1"
                            :label="filter._id"
                            >{{ filter.filter_name }}</el-checkbox
                          >
                        </el-checkbox-group>
                      </el-scrollbar>
                    </div>
                    <div
                      class="mt-1"
                      v-else-if="
                        selectedComponentType == 'CHART' &&
                        (selectedEntityData || selectedEntityRelationalData)
                      "
                    >
                      <h4>Chart type</h4>
                      <el-select
                        v-model="chartType"
                        filterable
                        default-first-option
                        class="mb-1"
                      >
                        <el-option
                          v-for="(field, index) of chartTypes"
                          :value="field.value"
                          :key="field.value + index"
                          :label="field.label"
                        >
                          <span>{{ field.label }}</span>
                        </el-option>
                      </el-select>
                      <h4>Select field</h4>
                      <el-select
                        v-model="pieChartField"
                        filterable
                        default-first-option
                        class="mb-1"
                        @change="fetchFieldOptionsForChart(pieChartField)"
                      >
                        <el-option
                          v-for="(field, index) of getTemplateFields"
                          :value="field.key"
                          :key="field.key + index"
                          :label="`${field.template_name} - ${field.label} `"
                        >
                          <span
                            >{{ field.template_name }} - {{ field.label }}</span
                          >
                        </el-option>
                      </el-select>
                      <h4 v-if="pieChartField">Select Options</h4>
                      <el-select
                        placeholder="Select options for chart"
                        multiple
                        v-model="pieChartFieldOptions"
                        v-if="pieChartField"
                        :clearable="true"
                        collapse-tags
                      >
                        <template
                          v-if="checkFieldType(pieChartField, 'ENTITY')"
                        >
                          <el-option
                            v-for="(data, index) of pieChartFieldEntityValues"
                            :key="index + 'entity' + data._id"
                            :value="data._id"
                            :label="getLabel(data, index, pieChartFieldEntity)"
                          >
                            <span>{{ getLabel(data, index, pieChartFieldEntity) }}</span>
                          </el-option>
                        </template>
                        <template v-else>
                          <el-option
                            v-for="(
                              option, index
                            ) of pieChartFieldSelectOptions"
                            :value="option"
                            :key="index"
                            :label="option"
                          >
                            <span>{{ option }}</span>
                          </el-option>
                        </template>
                      </el-select>
                      <h4>Filter</h4>
                      <!-- chartFilters -->
                      <el-select
                        placeholder="Select filter"
                        clearable
                        v-model="chartSelectedFilter"
                      >
                        <el-option
                          v-for="fl in chartFilters"
                          :key="fl._id"
                          :value="fl._id"
                          :label="fl.filter_name"
                        ></el-option>
                      </el-select>
                      <h4>Show total</h4>
                      <el-select
                        v-model="sum_fields"
                        placeholder="Total field"
                        class="mb-1"
                        clearable
                        multiple
                        :multiple-limit="pieChartFieldGroupBy ? 1 : null"
                        collapse-tags
                        @change="
                          sum_fields && sum_fields.length > 1
                            ? (pieChartFieldGroupBy = '')
                            : ''
                        "
                      >
                        <el-option
                          v-for="(field, index) of getTotalFields"
                          :value="field.key"
                          :key="field.key + index"
                          :label="`${field.template_name} - ${field.label} `"
                        >
                          <span
                            >{{ field.template_name }} - {{ field.label }}</span
                          >
                        </el-option>
                      </el-select>
                      <el-select
                        placeholder="Group by"
                        v-model="pieChartFieldGroupBy"
                        v-if="
                          pieChartField &&
                          (!sum_fields ||
                            !sum_fields.length ||
                            sum_fields.length == 1)
                        "
                        :clearable="true"
                        @change="getSelectedFieldOptions"
                      >
                        <el-option
                          v-for="(field, index) of getTemplateFields.filter(
                            (e) =>
                              e.key != pieChartField && e.input_type != 'DATE'
                          )"
                          :value="field.key"
                          :key="field.key + index"
                          :label="`${field.template_name} - ${field.label} `"
                        >
                          <span
                            >{{ field.template_name }} - {{ field.label }}</span
                          >
                        </el-option>
                      </el-select>
                      <el-checkbox
                        v-model="includeChartTotal"
                        v-if="pieChartFieldGroupBy"
                        >Include total</el-checkbox
                      >
                      <el-select
                        placeholder="Select options to group"
                        multiple
                        v-model="pieChartFieldGroupBySelectedOptions"
                        v-if="pieChartFieldGroupBy"
                        :clearable="true"
                        class="mt-1"
                        collapse-tags
                      >
                        <template
                          v-if="checkFieldType(pieChartFieldGroupBy, 'ENTITY')"
                        >
                          <el-option
                            v-for="(data, index) of groupByFieldEntityValues"
                            :key="index + 'entity' + data._id"
                            :value="data._id"
                            :label="getLabel(data, index, groupByFieldEntity)"
                          >
                            <span>{{ getLabel(data, index, groupByFieldEntity) }}</span>
                          </el-option>
                        </template>
                        <template v-else>
                          <el-option
                            v-for="(
                              option, index
                            ) of pieChartFieldGroupByOptions"
                            :value="option"
                            :key="index"
                            :label="option"
                          >
                            <span>{{ option }}</span>
                          </el-option>
                        </template>
                      </el-select>
                      <span
                        class="yale-blue mb-1"
                        style="font-size: 12px"
                        v-if="selectedComponentType == 'CHART'"
                      >
                        NOTE: 'SELECT', 'YES OR NO', 'MULTI SELECT', 'ENTITY'
                        fields can be used for chart representation
                      </span>
                    </div>
                    <div v-else-if="selectedComponentType == 'CUSTOM_FILTER'">
                      <h4>Filter by field</h4>
                      <el-select
                        v-model="customFilterField"
                        filterable
                        default-first-option
                        class="mb-1"
                        multiple
                        collapse-tags
                      >
                        <el-option
                          v-for="(field, index) of getTemplateFields"
                          :value="field.key"
                          :key="field.key + index"
                          :label="`${field.template_name} - ${field.label} `"
                        >
                          <span
                            >{{ field.template_name }} - {{ field.label }}</span
                          >
                        </el-option>
                      </el-select>
                    </div>
                    <br />
                    <div v-if="selectedComponentType == 'CALENDAR'">
                      <div style="display: inline-flex">
                        <h4>Select field:</h4>
                        <el-link
                          icon="el-icon-circle-plus"
                          :disabled="
                            selectedCalendarEntities.length == 0 &&
                            selectedCalendarRelationEntities.length == 0
                          "
                          @click="displayPopup"
                          style="
                            margin-top: 3px;
                            margin-left: 60px;
                            color: #f754a2;
                            height: 16px;
                            width: 16px;
                          "
                        ></el-link>
                      </div>
                      <div>
                        <div
                          v-for="dt in this.calenderDateField"
                          :key="dt.field"
                        >
                          <el-tag
                            v-if="
                              dt &&
                              dt.field &&
                              dt.field.includes('#') &&
                              dt.field.split('#')[2]
                            "
                            >{{ dt.field.split("#")[2] }}</el-tag
                          >
                        </div>
                      </div>
                    </div>
                    <div v-if="selectedComponentType == 'LOGINS'">
                      <div style="display: inline-flex">
                        <h4>Map field:</h4>
                        <el-link
                          icon="el-icon-circle-plus"
                          @click="displayLoginsPopup"
                          style="
                            margin-top: 3px;
                            margin-left: 60px;
                            color: #f754a2;
                            height: 16px;
                            width: 16px;
                          "
                        ></el-link>
                      </div>
                      <div>
                        <div v-if="this.loginsDataDetails.length > 0">
                          <el-tag>Data</el-tag>
                        </div>
                      </div>
                    </div>
                  </div>
                </el-scrollbar>
                <el-button
                  class="mt-1"
                  type="primary"
                  style="width: 100%"
                  @click="insertComponent"
                  :disabled="checkIsDisabled"
                  >Insert</el-button
                >
              </div>
            </el-col>
          </el-row>
        </div>
      </el-col>
      <el-col :span="getIsOpen ? 20 : 24">
        <div>
          <el-row
            v-if="
              (customDashboardData &&
                customDashboardData.allCustomFilters &&
                customDashboardData.allCustomFilters.length) ||
              customDashboardData.includeGlobalDateFilter
            "
            style="padding-left: 25px"
            :gutter="30"
          >
            <el-col :span="24">
              <CustomDashboardFilters
                :allDashboardstats="customDashboardData"
              ></CustomDashboardFilters>
            </el-col>
          </el-row>
          <el-row :gutter="30" style="padding-left: 25px">
            <el-col :span="24">
              <CustomDashboardStats
                :allDashboardstats="customDashboardData"
                :getRelationshipEntities="getRelationshipEntities"
                :getNestedRelationshipEntities="getNestedRelationshipEntities"
                :isFromApplicationuser="isEntityDashboard"
                :globalVariablesData="globalVariablesData"
              ></CustomDashboardStats>
            </el-col>
          </el-row>
          <el-scrollbar
            :style="
              getIsMobile
                ? 'height: 1000px !important;'
                : 'height: 75vh !important;'
            "
          >
            <div class="wrapper-grid" v-if="!refreshComponents">
              <draggable class="drag-grid" @end="switchPositions">
                <div
                  v-for="(
                    component, index
                  ) in customDashboardData.allTableComponents"
                  :style="
                    expendedIndexs.indexOf(index) !== -1 || getIsMobile
                      ? 'grid-column: 1/3;'
                      : ''
                  "
                  :key="
                    component.entity_id +
                    '_' +
                    (component.filter_id
                      ? component.filter_id
                      : component.pie_chart_field) +
                    index +
                    '_component'
                  "
                  class="grid-item"
                >
                  <CustomDashboardTable
                    v-if="component.type == 'TABLE'"
                    :getRelationshipEntities="getRelationshipEntities"
                    :component="component"
                    :key="
                      component.entity_id +
                      '_' +
                      component.filter_id +
                      index +
                      '_table'
                    "
                    :entity_id="component.entity_id"
                    :filter_id="component.filter_id"
                    :index="index"
                    :globalVariablesData="globalVariablesData"
                    :customDashboardData="customDashboardData"
                    :rawData="rawData"
                    @expendTable="expendTable"
                    @deleteComponent="deleteComponent"
                    @tableUpdate="tableUpdate"
                  ></CustomDashboardTable>
                  <CustomDashboardPieChart
                    v-else-if="component.type == 'CHART'"
                    :component="component"
                    :hide_options="false"
                    :hide_expend="false"
                    :index="index"
                    :customDashboardData="customDashboardData"
                    :globalVariablesData="globalVariablesData"
                    @expendTable="expendTable"
                    @deleteComponent="deleteComponent"
                    @tableUpdate="tableUpdate"
                    :getRelationshipEntities="getRelationshipEntities"
                  ></CustomDashboardPieChart>
                  <CustomDashboardCalendar
                    v-if="component.type == 'CALENDAR'"
                    :component="component"
                    :index="index"
                    :customDashboard="customDashboardData"
                    :globalVariablesData="globalVariablesData"
                    @expendTable="expendTable"
                    @deleteComponent="deleteComponent"
                    @tableUpdate="tableUpdate"
                  ></CustomDashboardCalendar>
                  <CustomDashboardCard
                    v-else-if="component.type == 'CARD'"
                    :component="component"
                    :key="
                      component.entity_id +
                      '_' +
                      component.filter_id +
                      index +
                      '_card'
                    "
                    :entity_id="component.entity_id"
                    :filter_id="component.filter_id"
                    :index="index"
                    :globalVariablesData="globalVariablesData"
                    :customDashboardData="customDashboardData"
                  ></CustomDashboardCard>
                  <CustomDashboardLogins
                    v-else-if="component.type == 'LOGINS'"
                    :component="component"
                    :index="index"
                    :customDashboard="customDashboardData"
                    @expendTable="expendTable"
                    @deleteComponent="deleteComponent"
                    @tableUpdate="tableUpdate"
                  ></CustomDashboardLogins>
                  <EntityViews
                    v-else-if="component.type == 'ENTITY_VIEW'"
                    :entityViewId="component.entity_view_id"
                    :fromCustomDashboard="true"
                    :index="index"
                    :component="component"
                    :customDashboardData="customDashboardData"
                    @deleteComponent="deleteComponent"
                    @expendTable="expendTable"
                    @viewUpdate="tableUpdate"
                    :hide_options="false"
                  >
                  </EntityViews>
                </div>
              </draggable>
            </div>
          </el-scrollbar>
        </div>
        <el-dialog
          title="Calendar Component"
          :visible.sync="centerDialogVisible"
          width="60%"
          :close-on-click-modal="false"
          :close-on-press-escape="false"
          draggable
        >
          <div v-if="tableData">
            <label>Component name</label>
            <el-input
              v-model="calendarName"
              placeholder="Enter component name"
              class="mb-1"
            ></el-input>
          </div>
          <el-table :data="tableData" border>
            <el-table-column prop="Contact Type" label="Fields" width="180">
              <template slot-scope="scope">
                <el-select
                  v-model="scope.row.field"
                  filterable
                  default-first-option
                  class="mb-1"
                  @change="getEntityFilters"
                  clearable
                >
                  <el-option
                    v-for="(field, index) of getTemplateDateFields"
                    :value="field.key"
                    :key="field.key + index"
                    :label="`${field.entity_name} - ${field.template_name} - ${field.label} `"
                    :disabled="
                      (tableData || []).findIndex(
                        (ex) => ex.field == field.key
                      ) != -1
                    "
                  >
                    <span>
                      {{ field.entity_name }} - {{ field.template_name }} -
                      {{ field.label }}
                    </span>
                  </el-option>
                </el-select>
              </template>
            </el-table-column>

            <el-table-column
              prop="Custom Message"
              label="Custom Message"
              width="250"
            >
              <template slot-scope="scope">
                <div
                  v-if="
                    scope.row.field &&
                    scope.row.field.includes('#') &&
                    scope.row.field.split('#')[0]
                  "
                >
                  <CustomEntityMention
                    :entity_id="scope.row.field.split('#')[0]"
                    :data="scope.row"
                  ></CustomEntityMention>
                </div>
              </template>
            </el-table-column>

            <el-table-column prop="Filter" label="Filter">
              <template slot-scope="scope">
                <el-select
                  clearable
                  v-model="scope.row.filter"
                  placeholder="Select filter"
                  v-if="
                    scope.row.field &&
                    scope.row.field.includes('#') &&
                    scope.row.field.split('#')[0]
                  "
                >
                  <el-option
                    v-for="(op, i) of getCurrenEntityFilter(
                      scope.row.field.split('#')[0]
                    )"
                    :key="i + op._id"
                    :value="op._id"
                    :label="op.filter_name"
                  ></el-option>
                </el-select>
              </template>
            </el-table-column>

            <el-table-column prop="show every year" label="Show every year">
              <template slot-scope="scope">
                <div class="d-flex">
                  <div>
                    <el-checkbox
                      class="mr-2"
                      type="textarea"
                      v-model="scope.row.show_every_year"
                    ></el-checkbox>

                    <el-link
                      class="mr-2"
                      @click.native.prevent="deleteRow(scope.$index, tableData)"
                      type="danger"
                      icon="el-icon-delete"
                    ></el-link>
                  </div>
                  <div>
                    <el-color-picker
                      size="mini"
                      v-model="scope.row.color"
                    ></el-color-picker>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="Show background color">
              <template slot-scope="scope">
                <div>
                  <el-checkbox
                    type="textarea"
                    v-model="scope.row.applyBackground"
                  ></el-checkbox>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <br />
          <br />
          <div class="calendar-header">
            <div class="child-a">
              <p><b>HINT:</b>Type @ to insert entity field</p>
            </div>
            <div class="child-a">
              <el-link
                icon="el-icon-circle-plus"
                type="primary"
                @click="addNewRow"
                style="float: right"
                >Add</el-link
              >
            </div>
          </div>

          <span slot="footer" class="dialog-footer">
            <el-button @click="resetEventModal">Cancel</el-button>
            <el-button
              type="primary"
              @click="insertCalendarFields"
              :disabled="disableSave"
              >Save</el-button
            >
          </span>
        </el-dialog>
        <el-dialog
          title="Add table columns"
          :visible.sync="openTableColumsModal"
          width="50%"
          :close-on-click-modal="false"
          :close-on-press-escape="false"
          draggable
        >
          <el-row>
            <el-link
              icon="el-icon-circle-plus"
              type="primary"
              @click="addNewColumn"
              style="float: right"
              :underline="false"
              >Add</el-link
            >
          </el-row>
          <el-row>
            <el-scrollbar
              wrap-style="max-height: 250px; width: 100% !important;"
            >
              <el-table :data="tableFieldGroupByFieldsModal" border>
                <el-table-column
                  prop="Group"
                  label="Is sub group?"
                  width="180"
                  v-if="tableFieldSubGroupBy"
                >
                  <template slot-scope="scope">
                    <el-checkbox
                      v-model="scope.row.sub_group"
                      :disabled="scope.row.key ? true : false"
                      >Is sub group?</el-checkbox
                    >
                  </template>
                </el-table-column>
                <el-table-column prop="Column" label="Column" width="180">
                  <template slot-scope="scope">
                    <el-select
                      v-model="scope.row.key"
                      placeholder="Table columns"
                      v-if="tableFieldGroupBy"
                      clearable
                      @change="fillLabel(scope.row.key, scope.$index)"
                    >
                      <el-option
                        label="Count"
                        value="count"
                        :disabled="
                          tableFieldGroupByFieldsModal.find(
                            (e) =>
                              e.key == 'count' &&
                              scope.row.sub_group == e.sub_group
                          )
                            ? true
                            : false
                        "
                      ></el-option>
                      <el-option-group label="Sum">
                        <el-option
                          v-for="(field, index) of getTotalFields"
                          :value="field.key"
                          :key="field.key + index"
                          :label="`${field.template_name} - ${field.label} `"
                          :disabled="
                            tableFieldGroupByFieldsModal.find(
                              (e) =>
                                e.key == field.key &&
                                scope.row.sub_group == e.sub_group
                            )
                              ? true
                              : false
                          "
                        >
                          <span
                            >{{ field.template_name }} - {{ field.label }}</span
                          >
                        </el-option>
                      </el-option-group>
                    </el-select>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="Contact Type"
                  label="Column name"
                  width="180"
                >
                  <template slot-scope="scope">
                    <el-input
                      placeholder="Enter name"
                      v-model="scope.row.name"
                    ></el-input>
                  </template>
                </el-table-column>
                <el-table-column prop="Actions" label="Actions" width="180">
                  <template slot-scope="scope">
                    <el-link
                      @click.native.prevent="
                        deleteRow(scope.$index, tableFieldGroupByFieldsModal)
                      "
                      type="danger"
                      icon="el-icon-delete"
                      :underline="false"
                    ></el-link>
                  </template>
                </el-table-column>
              </el-table>
            </el-scrollbar>
          </el-row>
          <span slot="footer" class="dialog-footer">
            <el-button @click="openTableColumsModal = false">Cancel</el-button>
            <el-button
              type="primary"
              @click="saveTableGroupBy"
              :disabled="checkTableGroupByField"
              >Save</el-button
            >
          </span>
        </el-dialog>
        <dialog-component
          title="Logins Component"
          :visible="centerLoginsDialogVisible"
          width="70%"
          draggable
        >
          <label>start time</label>
          <el-input
            v-model="start_button"
            placeholder="Enter component name"
            class="mb-1"
          ></el-input>
          <label>end time</label>
          <el-input
            v-model="end_button"
            placeholder="Enter component name"
            class="mb-1"
          ></el-input>
          <el-table :data="info" border>
            <el-table-column label="componentinfo">
              <template slot-scope="scope">
                {{ scope.row.component }}
              </template>
            </el-table-column>
            <el-table-column prop="Contact Type" label="Fields" width="250">
              <template slot-scope="scope">
                  <el-select
                v-model="scope.row.field"
                size="mini"
                clearable
              >
                <el-option
                v-for="(field, i) of allDateTimeFields"
                  :key="field + i"
                  :value="field.template_key"
                  :label="field.label"
                  :disabled="
                    isFieldDisabled(field)
                    "
                    
                >
                </el-option>
              </el-select>
                </template>
              </el-table-column>
              </el-table>
              <el-row>

        <!-- <el-col   :span="12" v-if="displayAutoLogout">
              <div style="display: flex; flex-direction: column; align-items: flex-start;">
                <div style="display: flex; align-items: center;">
                <label>Auto logout </label>
        </div>
        <el-input
          v-model="auto_logout"
          type ="number"
          placeholder="Enter number "
          class="mb-1"
          style="width:95% ;"
        ></el-input>
              </div>
            </el-col> -->
            <el-col :span="12" >
              <div style="display: flex; flex-direction: column; align-items: flex-start;"   v-if="
                        isEntityDashboard &&
                        !selectedEntityData 
                      ">
                <div style="display: flex; align-items: center;" >
                  <label>Select User Field</label>
                  </div>
                  <el-select
                v-model="user_field"
                clearable
                style="width:95%;"
              >
                <el-option
                v-for="(field, i) of entityField"
                  :key="field + i"
                  :value="field.template_key"
                  :label="field.label"
                >
                </el-option>
              </el-select>
                  </div>

            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <div v-if="shouldDisplayInput">
                <div style="display: flex; align-items: center">
                  <label>No. of days</label>
                </div>
                <el-input
                  v-model="Days"
                  type="number"
                  placeholder="Enter number of days"
                  class="mb-1"
                  style="width: 95%"
                ></el-input>
              </div>
            </el-col>
          </el-row>
          <span slot="footer" class="dialog-footer">
            <el-button @click="centerLoginsDialogVisible = false"
              >Cancel</el-button
            >
            <el-button
              type="primary"
              @click="saveLoginsData"
              :disabled="disableloginSave"
              >Save</el-button
            >
          </span>
        </dialog-component>
      </el-col>
    </el-row>
  </section>
</template>
<script>
import { mapGetters } from "vuex";
import { bus } from "../../main";
import MobileRelatedHelper from "@/mixins/MobileRelatedHelper";
import CustomDashboardConfig from "@/mixins/CustomDashboardHelper.js";
//import EntitiesHelper from "@/mixins/EntitiesHelper";
import NavigationHelper from "@/mixins/navigationHelper";
import draggable from "vuedraggable";
// import axios from "@/config/axios";
export default {
  mixins: [MobileRelatedHelper, CustomDashboardConfig,NavigationHelper],
  components: {
    CustomDashboardTable: () => import("./customDashboardTable.vue"),
    CustomDashboardStats: () => import("./customDashboardStats.vue"),
    CustomDashboardPieChart: () => import("./customDashboardPieChart.vue"),
    CustomDashboardFilters: () => import("./customDashboardFilters.vue"),
    CustomDashboardCalendar: () => import("./customDashboardCalendar.vue"),
    draggable,
    CustomDashboardCard: () => import("./customDashboardCard.vue"),
    CustomEntityMention: () =>
      import("@/components/widgets/entityMention/index.vue"),
    CustomDashboardLogins: () => import("./customDashboardLogins.vue"),
    EntityViews:() => import("@/components/entity/EntityViews.vue")
  },
  data() {
    return {
      customDashboardData: {
        allTableComponents: [],
        allStatComponents: [],
        allCustomFilters: [],
        name: "",
        includeGlobalDateFilter: false,
        allowUserChangeOrder: true,
      },
      calendarName: "",
      rawData: null,
      centerDialogVisible: false,
      filters: [],
      selectedFilter: [],
      selectedEntityData: "",
      selectedCalendarEntities: [],
      selectedCalendarRelationEntities: [],
      selectedEntityRelationalData: "",
      filterSearchString: "",
      loading: false,
      layout: "div",
      offCanvas: true,
      windowWidth: 1500,
      refresh: false,
      expendedIndexs: [],
      loadingText: "Loading...",
      selectedComponentType: "TABLE",
      pieChartField: "",
      pieChartFieldGroupBy: "",
      sum_fields: [],
      pieChartFieldGroupByOptions: [],
      includeChartTotal: false,
      calenderDateField: [],
      chartType: "",
      pieChartFieldOptions: [],
      pieChartFieldSelectOptions : [],
      pieChartFieldEntityValues : [],
      pieChartFieldEntity : null,
      getFieldOptions: [],
      customFilterField: [],
      componentTypes: [
        {
          label: "Table",
          value: "TABLE",
        },
        {
          label: "Chart",
          value: "CHART",
        },
        // {
        //   label: "Card",
        //   value: "CARD"
        // },
        {
          label: "Custom filters",
          value: "CUSTOM_FILTER",
        },
        {
          label: "Calendar",
          value: "CALENDAR",
        },
        {
          label: "Logins",
          value: "LOGINS",
        },
        {
          label : "Entity Views",
          value : 'ENTITY_VIEW'
        }
      ],
      chartTypes: [
        {
          label: "Pie",
          value: "PIE",
        },
        {
          label: "Bar",
          value: "BAR",
        },
        {
          label: "Doughnut",
          value: "DOUGHNUT",
        },
        {
          label: "Line",
          value: "LINE",
        },
      ],
      getTemplateFields: [],
      getTemplateDateFields: [],
      globalVariablesData: [],
      tableData: [],
      isEntityDashboard: false,
      permittedEntities: [],
      permittedRelatedEntities: [],
      permittedNestedRelatedEntities: [],
      relationshipEntities: [],
      allEntityFilters: [],
      getTotalFields: [],
      options: [
        {
          value: "Option1",
          label: "Option1",
        },
        {
          value: "Option2",
          label: "Option2",
        },
      ],
      pieChartFieldGroupBySelectedOptions: [],
      selectedValues: [],
      groupByFieldEntityValues: [],
      groupByFieldEntity: null,
      chartFilters: [],
      chartSelectedFilter: "",
      tableFieldGroupBy: "",
      tableFieldSubGroupBy: "",
      tableFieldGroupByFields: [
        {
          key: "count",
          name: "Count",
          type: "count",
          sub_group: false,
        },
      ],
      tableFieldGroupByFieldsModal: [],
      openTableColumsModal: false,
      tableFieldGroupBySelectedOptions: [],
      allTemplates: [],
      allowOtherShowData: false,
      // getAllCompanyTemplatesData: null,
      centerLoginsDialogVisible: false,
      actionOptions: [
        { label: "Login", value: "login" },
        { label: "Logout", value: "logout" },
        { label: "Duration", value: "duration" },
        // Add more options as needed
      ],
      info: [
        { component: "Date", field: "" },
        { component: "Login", field: "" },
        { component: "Logout", field: "" },
        { component: "Duration", field: "" },
      ],
      allEntityFields: [],
      allDateTimeFields: [],
      selectedLoginEntity: "",
      selectedEntityView : '',
      loginsDataDetails: [],
      getTemplateTimeFields: [],
      start_button: "",
      end_button: "",
      isloginIndex: [],
      Days: "",
      shouldDisplayInput: false,
      displayAutoLogout: false,
      user_field: "",
      entityField: [],
      auto_logout: "",
      selectedFieldType: "",
      dateTimeFieldObject: null,
      fieldSelected: false,
      selectedFirstField: null,
      refreshComponents : false
    };
  },
  computed: {
    ...mapGetters("entities", [
      "getAllEntities",
      "getEntityDataByEntityId",
      "geCustomDashboardPieStats",
      "getEntityRecordsForTable",
      "getChildEntityDataById",
    ]),
    ...mapGetters("filters", [
      "getAllEntityFilters",
      "getCreateNewFilter",
      "getUpdateFilterStatus",
      "getDeleteFilterStatus",
    ]),
    ...mapGetters("customDashboard", [
      "getSingleDashboardData",
      "getUpdateDashboardStatus",
      "getUpdateDashboardError",
      "getCreateNewDashboard",
    ]),
    ...mapGetters("navigationOpen", [
      "getIsOpen",
      "getIsCollapse",
      "getIsMobile",
    ]),
    ...mapGetters("companyTemplates", ["getAllCompanyTemplatesData"]),
    ...mapGetters("globalVariables", ["getAllGlobalVariables"]),
    ...mapGetters("applicationUsersPermission", [
      "getCreatePermissionStatus",
      "getEntityPermission",
      "getUpdatePermissionStatus",
    ]),
    ...mapGetters("entityRelationships", ["getEntityRelationships"]),
    ...mapGetters("sso",["getAuthenticatedSSOToken"]),
    ...mapGetters("entityViews", ["getAllEntityViews"]),
    getAllEntitiesData() {
      if (this.getAllEntities && this.getAllEntities?.data) {
        if (this.isEntityDashboard) {
          return (this.getAllEntities.data || []).filter(
            (e) => this.permittedEntities.indexOf(e._id) != -1
          );
        }
        return this.getAllEntities.data;
      }
      return [];
    },
    getNestedRelationshipEntities() {
      if (this.getAllEntities?.data) {
        if (this.getEntityPermission?._id && this.permittedNestedRelatedEntities && this.permittedNestedRelatedEntities.length) {
          return this.permittedNestedRelatedEntities.map(rl => {
            let [e1, e2] = rl.split("#");
            let entity1 = this.getAllEntities.data.find(e => e._id == e1);
            let entity2 = this.getAllEntities.data.find(e => e._id == e2);
            if (entity1?.name && entity2?.name) {
              return {
               name: entity1.name +' / '+ entity2.name,
               key: rl
              }
            }
          });
        }
        return [];
      }
      return [];
    },
    getRelationshipEntities() {
      if (this.getAllEntities?.data) {
        if (this.getEntityPermission?._id) {
          return this.getAllEntities.data.filter(
            (e) => this.permittedRelatedEntities.indexOf(e._id) != -1
          );
        }
        if (this.relationshipEntities && this.relationshipEntities.length) {
          return this.getAllEntities.data.filter(
            (e) => this.relationshipEntities.indexOf(e._id) != -1
          );
        }
        return [];
      }
      return [];
    },
    getCurrentEntitiesData() {
      let currentEntities = [];
      if (this.customDashboardData) {
        if (
          this.customDashboardData.allTableComponents &&
          this.customDashboardData.allTableComponents.length
        ) {
          currentEntities = [
            ...currentEntities,
            ...this.customDashboardData.allTableComponents.flatMap(
              (e) => e.entity_id
            ),
          ];
        }
        if (
          this.customDashboardData.allStatComponents &&
          this.customDashboardData.allStatComponents.length
        ) {
          currentEntities = [
            ...currentEntities,
            ...this.customDashboardData.allStatComponents.flatMap(
              (e) => e.entity_id
            ),
          ];
        }
      }
      return this.getAllEntities && this.getAllEntities.data
        ? this.getAllEntities.data.filter(
            (e) => currentEntities.indexOf(e._id) !== -1
          )
        : [];
    },
    checkIsDisabled() {
      if (this.selectedEntityRelationalData) {
        return false;
      } else if (this.selectedComponentType == "LOGINS") {
        return !this.loginsDataDetails.length;
      } else if(this.selectedComponentType == 'ENTITY_VIEW'){
        return this.selectedEntityView == ''
      } else {
        if (this.selectedComponentType == "CALENDAR") {
          return (
            !this.calenderDateField.length ||
            (!this.selectedCalendarEntities.length &&
              !this.selectedCalendarRelationEntities.length)
          );
        }
        return (
          !this.selectedComponentType ||
          (!this.selectedEntityData &&
            (!this.selectedCalendarEntities ||
              !this.selectedCalendarEntities.length)) ||
          // (this.selectedComponentType == "TABLE" &&
          // !this.selectedFilter.length &&
          // !this.selectedEntityRelationalData) ||
          (this.selectedComponentType == "CHART" &&
            (!this.pieChartField || !this.chartType)) ||
          (this.selectedComponentType == "CALENDAR" &&
            this.calenderDateField.length == 0)
        );
      }
    },
    disableSave() {
      if (this.tableData.length == 0) {
        return true;
      }
      let calendarname = this.calendarName;
      if(calendarname.length == 0){
        return true;
      }
      let empty = this.tableData.find(
        (e) =>
          !e.field ||
          !e.custom_message ||
          (typeof e.custom_message == "object" &&
            Object.keys(e.custom_message).length == 0) ||
          !e?.custom_message?.content[0]?.content
      );
      if (empty) {
        return true;
      }
      return false;
    },
    disableloginSave() {
      if (this.info.length == 0) {
        return true;
      }
      let empty = this.info.find((e) => !e.field);
      if (empty || this.start_button == "" || this.end_button == "") {
        return true;
      }
      if (this.shouldDisplayInput == true) {
        if (this.Days == "") {
          return true;
        } else {
          return false;
        }
      }
      return false;
    },
    checkTableGroupByField() {
      if (
        this.tableFieldGroupByFieldsModal &&
        this.tableFieldGroupByFieldsModal.length
      ) {
        let empty = this.tableFieldGroupByFieldsModal.find(
          (e) => !e || !e.name || !e.key
        );
        return empty ? true : false;
      }
      return (
        !this.tableFieldGroupByFieldsModal ||
        !this.tableFieldGroupByFieldsModal.length
      );
    },
    isEntitySelectDisabled() {
      if (this.selectedEntityRelationalData) {
        return true;
      } else {
        return false;
      }
    },
    isRelationalSelectDisabled() {
      if (this.selectedLoginEntity) {
        return true;
      } else {
        return false;
      }
    },
  },
  async mounted() {
    if (this.getIsMobile) {
      this.$message.warning("dashboard edit is not allowed in small screens");
      this.$router.push({ path: "/custom/dashboard-list" });
    }
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    if (this.$route.name == "entity-custom-dashboard-config") {
      this.isEntityDashboard = true;
    }
    await this.getNecessaryInfo();
    await this.checkUrlType()
  },
  methods: {
    async fetchEntityDetails(
      id,
      includeLabel = false,
      includeSlugKey = false,
      include_data_tables = false,
      ogLabel = false
    ) {
      await this.$store.dispatch("entities/fetchChildEntityById", id);
      let allFields = [];
      if (
        this.getChildEntityDataById &&
        this.getChildEntityDataById.templates
      ) {
        this.getChildEntityDataById.templates.forEach((template) => {
          if (template?.templateInfo?.sections[0]?.fields) {
            allFields = [
              ...allFields,
              ...this.getTemplateFieldsInfo(
                template?.templateInfo,
                includeLabel,
                includeSlugKey,
                include_data_tables,
                ogLabel
              ),
            ];
          }
        });
        return allFields;
      }
    },
    getTemplateFieldsInfo(
      data,
      includeLabel,
      includeSlugKey = false,
      include_data_tables = false,
      ogLabel = false
    ) {
      let fields = [];
      data.sections.forEach((e) => {
        fields = [...fields, ...e.fields];
      });

      fields = fields.map((e) => {
        e.template_id = data._id;
        e.template_key = data._id + "#" + e.key;
        e.template_name = data.name;
        if (includeLabel) {
          e.label = data.name + " - " + e.label;
        } else if (!ogLabel) {
          e.label = data.slug + "#" + e.key;
        }
        if (includeSlugKey) {
          e.key = data.slug + "_" + e.key;
        }
        return e;
      });
      if (include_data_tables) {
        //have to check data table fields
        let dataTableFields = fields.filter(
          (e) => e?.inputType == "DATA_TABLE"
        );
        if (dataTableFields && dataTableFields.length) {
          dataTableFields.forEach((fl) => {
            if (fl?.data_table_columns) {
              fields = [
                ...fields,
                ...fl?.data_table_columns.map((e) => {
                  e.data_table_key = fl.key;
                  e.template_id = data._id;
                  e.label = fl.label + "-" + e.label;
                  e.all_duplicate_rules = fl.all_duplicate_rules;
                  e.duplicate_query_type = fl.duplicate_query_type;
                  if (
                    fl.input_type == "ENTITY_VARIABLE" &&
                    fl?.relationship_key
                  ) {
                    e.table_relationship_key = fl.relationship_key;
                  }
                  if (includeLabel) {
                    e.label = data.name + " - " + e.label;
                  } else {
                    e.label = data.slug + "#" + e.key;
                  }
                  if (includeSlugKey) {
                    e.key = data.slug + "_" + e.key;
                  }
                  e.template_id = data._id;
                  e.template_key = data._id + "#" + e.key;
                  e.template_name = data.name;
                  e["isDataTableField"] = true;
                  // if (include_data_table_key) {
                  //   e.key = e.data_table_key + "/" + e.key;
                  // }
                  return e;
                }),
              ];
            }
          });
        }
        fields = fields.filter((e) => {
          return e.inputType != "DATA_TABLE";
        });
      }
      return fields;
    },
    isActionDisabled(index, selectedAction) {
      if (index > 0 && this.tableDatas[index - 1].action === selectedAction) {
        return true;
      }
      return false;
    },
    isFieldDisabled(field) {
      let fieldSelected = false;
      let selectedFirstField;
      this.info.map((e) => {
        if (e.field) {
          fieldSelected = true;
          selectedFirstField = e;
        }
      });
      if (!fieldSelected) {
        this.selectedFieldType = "";
        this.dateTimeFieldObject = null;
        this.shouldDisplayInput = false;
        return false;
      }
      if (!this.dateTimeFieldObject) {
        let key = selectedFirstField.field;
        this.dateTimeFieldObject = this.allDateTimeFields.find(
          (e) => e.template_key == key
        );
        if (
          this.dateTimeFieldObject &&
          this.dateTimeFieldObject.isDataTableField
        ) {
          this.selectedFieldType = "data_table";
          this.shouldDisplayInput = true;
          this.displayAutoLogout = false;
        } else {
          this.selectedFieldType = "normal";
          this.shouldDisplayInput = false;
          this.displayAutoLogout = true;
        }
      }

      if (!this.dateTimeFieldObject) {
        return false;
      }
      if (
        this.selectedFieldType == "data_table" &&
        field.isDataTableField &&
        this.info.findIndex((ex) => ex.field == field.template_key) == -1
      ) {
        return false;
      } else if (
        this.selectedFieldType == "normal" &&
        !field.isDataTableField &&
        this.info.findIndex((ex) => ex.field == field.template_key) == -1
      ) {
        return false;
      } else {
        return true;
      }
    },
    async addSubGroupCount() {
      if (this.tableFieldSubGroupBy) {
        this.tableFieldGroupByFields.push({
          key: "count",
          name: "Count",
          type: "count",
          sub_group: true,
        });
        await this.getSelectedFieldOptions(this.tableFieldSubGroupBy);
      }
    },
    resetEventModal() {
      this.openNewEventLoading = false;
      this.openNewEventModal = false;
      this.tableData = [];
      this.calenderDateField = [];
      this.centerDialogVisible = false;
    },
    saveTableGroupBy() {
      this.tableFieldGroupByFields = [];
      this.tableFieldGroupByFields = [...this.tableFieldGroupByFieldsModal];
      this.openTableColumsModal = false;
    },
    fillLabel(key, index) {
      if (key) {
        if (key == "count") {
          this.tableFieldGroupByFieldsModal[index]["name"] = "Count";
        } else {
          let field = this.getTotalFields.find((e) => e.key == key),
            group_by_fields = ["SELECT", "ENTITY", "MULTI_SELECT"];
          this.tableFieldGroupByFieldsModal[index]["type"] =
            field && group_by_fields.indexOf(field.inputType) != -1
              ? "groupby"
              : "sum";
          this.tableFieldGroupByFieldsModal[index]["name"] =
            field && field.label ? field.label : "";
        }
      }
    },
    addNewColumn() {
      this.tableFieldGroupByFieldsModal.push({
        name: "",
        key: "",
        type: "",
      });
    },
    openTableColums() {
      this.tableFieldGroupByFieldsModal = [];
      this.tableFieldGroupByFieldsModal = [...this.tableFieldGroupByFields];
      this.openTableColumsModal = true;
    },
    getCurrenEntityFilter(id) {
      if (id) {
        let found = this.allEntityFilters.find((e) => e.entity_id == id);
        if (found) {
          return found.filters;
        }
        return [];
      }
      return [];
    },
    getEntityFilters(row) {
      if (
        row &&
        row.includes("#") &&
        row.split("#")[0] &&
        !this.allEntityFilters[row.split("#")[0]]
      ) {
        let field = this.getTemplateDateFields.find((e) => e.key == row);
        let index = this.tableData.findIndex((e) => e.field == row);
        console.log("field field field", field);
        if (field?.inputType) {
          this.tableData[index]["inputType"] = field.inputType;
        }
        if (field?.is_relational_field) {
          this.tableData[index]["is_relational_field"] =
            field.is_relational_field;
        }
        if (
          index > -1 &&
          (!this.tableData[index].custom_message ||
            (typeof this.tableData[index].custom_message == "object" &&
              Object.keys(this.tableData[index].custom_message).length == 0))
        ) {
          let entity = (this.getAllEntities.data || []).find(
            (e) => e._id == row.split("#")[0]
          );
          if (entity?.primaryFields && entity?.primaryFields[0]) {
            let temp = (this.allTemplates || []).find(
              (e) => e._id == entity?.primaryFields[0].template_id
            );
            this.tableData[index]["custom_message"] = {
              type: "doc",
              content: [
                {
                  type: "paragraph",
                  content: [
                    {
                      type: "mention",
                      attrs: {
                        id: temp?.slug + "#" + entity?.primaryFields[0].key,
                        label: null,
                      },
                    },
                    {
                      type: "text",
                      text: " " + row.split("#")[2],
                    },
                  ],
                },
              ],
            };
          }
        }

        this.fetchEntityFilters(row.split("#")[0]);
      }
    },
    async fetchEntityFilters(entityId) {
      await this.$store.dispatch("filters/fetchAllEntityFilters", {
        entityId: entityId,
      });
      if (this.getAllEntityFilters) {
        this.allEntityFilters.push({
          entity_id: entityId,
          filters: this.getAllEntityFilters,
        });
      }
    },
    insertCalendarFields() {
      if (this.tableData.length > 0) {
        this.calenderDateField = [...this.tableData];
        this.tableData = [];
        this.centerDialogVisible = false;
        this.insertComponent();
      } else {
        console.log("add");
      }
    },
    deleteRow(index, rows) {
      rows.splice(index, 1);
    },
    addNewRow() {
      let field = {
        field: "",
        custom_message: {},
        show_every_year: false,
        filter_id: "",
        is_relational_field: false,
        inputType: "DATE",
        color: "#f754b9",
        applyBackground: false,
      };
      this.refresh = true;
      this.tableData.push(field);
      setTimeout(() => {
        this.refresh = false;
      }, 500);
    },
    displayPopup() {
      if (this.calenderDateField && this.calenderDateField.length) {
        this.tableData = [...this.calenderDateField];
      }
      this.centerDialogVisible = true;
    },
    displayLoginsPopup() {
      this.centerLoginsDialogVisible = true;
    },
    async getLoginEntityDateFields() {
      if (this.selectedLoginEntity) {
        this.allEntityFields = await this.fetchEntityDetails(
          this.selectedLoginEntity,
          true,
          false,
          true
        );
        this.allDateTimeFields = this.allEntityFields.filter(
          (e) => e.input_type == "TIME" || e.input_type == "DATE"
        );
        this.entityField = this.allEntityFields.filter(
          (e) => e.input_type == "ENTITY"
        );
      } else if (this.isEntityDashboard && this.selectedEntityRelationalData) {
        this.allEntityFields = await this.fetchEntityDetails(
          this.selectedEntityRelationalData,
          true,
          false,
          true
        );
        this.allDateTimeFields = this.allEntityFields.filter(
          (e) => e.input_type == "TIME" || e.input_type == "DATE"
        );
        this.entityField = this.allEntityFields.filter(
          (e) => e.input_type == "ENTITY"
        );
      }
    },
    saveLoginsData() {
      this.loginsDataDetails = [...this.info];
      this.info = [];
      this.centerLoginsDialogVisible = false;
    },
    getEntityDateFields() {
      this.getTemplateDateFields = [];
      if (this.selectedCalendarEntities.length) {
        this.selectedCalendarEntities.forEach((entity_id) => {
          const entity = (this.getAllEntities.data || []).find(
            (e) => e._id == entity_id
          );
          const templateIds = (entity.templates || []).flatMap(
            (e) => e.template_id
          );
          const entityTemplates = this.allTemplates.filter(
            (e) => templateIds.indexOf(e._id) !== -1
          );
          entityTemplates.forEach((e) => {
            let data = JSON.parse(JSON.stringify(e));
            this.getTemplateDateFields = [
              ...this.getTemplateDateFields,
              ...this.getTemplateFieldsByType(
                data,
                ["DATE", "DATE_TIME", "DATE_TIME_RANGE", "DATE_RANGE"],
                entity
              ),
            ];
          });
        });
      }
      if (this.selectedCalendarRelationEntities.length) {
        this.selectedCalendarRelationEntities.forEach((entity_id) => {
          const entity = (this.getAllEntities.data || []).find(
            (e) => e._id == entity_id
          );
          const templateIds = (entity.templates || []).flatMap(
            (e) => e.template_id
          );
          const entityTemplates = this.allTemplates.filter(
            (e) => templateIds.indexOf(e._id) !== -1
          );
          entityTemplates.forEach((e) => {
            let data = JSON.parse(JSON.stringify(e));
            let fields = this.getTemplateFieldsByType(
              data,
              ["DATE", "DATE_TIME", "DATE_TIME_RANGE", "DATE_RANGE"],
              entity
            );
            fields = fields.map((el) => {
              el.is_relational_field = true;
              return el;
            });
            this.getTemplateDateFields = [
              ...this.getTemplateDateFields,
              ...fields,
            ];
          });
        });
      }
    },
    switchPositions(evt) {
      if (
        evt &&
        typeof evt.oldIndex == "number" &&
        typeof evt.newIndex == "number" &&
        this.customDashboardData.allTableComponents[evt.oldIndex]
      ) {
        let data = JSON.parse(
          JSON.stringify(
            this.customDashboardData.allTableComponents[evt.oldIndex]
          )
        );
        let data1 = JSON.parse(
          JSON.stringify(
            this.customDashboardData.allTableComponents[evt.newIndex]
          )
        );
        data.order = evt.newIndex;
        data1.order = evt.oldIndex;
        this.customDashboardData.allTableComponents.splice(
          evt.oldIndex,
          1,
          data1
        );
        this.customDashboardData.allTableComponents.splice(
          evt.newIndex,
          1,
          data
        );
      }
    },

    getRandomKey() {
      return (Math.random() + 1).toString(36).substring(7);
    },
    async fetchFieldOptionsForChart(field) {
      this.pieChartFieldOptions = []
      await this.fetchFieldOptions(field)
    },
    tableUpdate(data) {
      this.customDashboardData.allTableComponents[data.index] = {
        ...data.data,
      };
    },
    async saveDashboard() {
      if (this.getIsMobile) {
        this.$message.warning("dashboard edit is not allowed in small screens");
        this.$router.push({ path: "/custom/dashboard-list" });
      }
      this.loading = true;
      bus.$emit("fetch-all-stats", {});
      let params = {
        name: this.customDashboardData.name,
        components: [
          ...this.customDashboardData.allStatComponents,
          ...this.customDashboardData.allTableComponents,
          ...this.customDashboardData.allCustomFilters,
        ],
        include_global_date_filter:
          this.customDashboardData.includeGlobalDateFilter,
        allow_user_change_order: this.customDashboardData.allowUserChangeOrder,
      };
      if (this.$route.params.entityId) {
        params = { ...params, ...{ entity_id: this.$route.params.entityId } };
      }
      let id = this.$route.params.dashboardId || this.$route.query.dashboardId;

      if (id) {
        await this.$store.dispatch("customDashboard/updateDashboard", {
          id: id,
          params: params,
        });
        if (this.getUpdateDashboardStatus) {
          this.$notify({
            title: "Success",
            message: "Dashboard updated successfully",
            type: "success",
          });
          this.goBack();
        } else {
          let msg =
            this.getUpdateDashboardError && this.getUpdateDashboardError.message
              ? this.getUpdateDashboardError.message
              : "Error while editing dashboard";
          this.$notify({
            title: "Error",
            message: msg,
            type: "error",
          });
        }
      } else {
        await this.$store.dispatch(
          "customDashboard/fetchCreateNewDashboard",
          params
        );
        if (this.getCreateNewDashboard && this.getCreateNewDashboard._id) {
          this.$notify({
            title: "Success",
            message: "Dashboard updated successfully",
            type: "success",
          });
          // this.$router.push({
          //   path: "/entity",
          // });
          this.goBack();
        }
      }
      this.loading = false;
    },
    expendTable(index) {
      if (this.expendedIndexs.indexOf(index) == -1) {
        this.customDashboardData.allTableComponents[index]["is_expand"] = true;
        this.expendedIndexs.push(index);
      } else {
        this.expendedIndexs.splice(this.expendedIndexs.indexOf(index), 1);
        this.customDashboardData.allTableComponents[index]["is_expand"] = false;
      }
    },
    deleteComponent(index) {
      this.refreshComponents = true
      this.customDashboardData.allTableComponents.splice(index, 1);
      setTimeout(() => {
        this.refreshComponents = false;
      }, 500);
    },
    insertComponent() {
      if (this.selectedComponentType == "CHART") {
        this.insertChartComponent();
        this.resetFormData();
      } else if (this.selectedComponentType == "CUSTOM_FILTER") {
        this.insertFilterComponent();
      } else if (this.selectedComponentType == "CALENDAR") {
        this.insertCalendarComponent();
      } else if (this.selectedComponentType == "CARD") {
        this.insertCardComponent();
      } else if (this.selectedComponentType == "LOGINS") {
        this.insertLoginsComponent();
      } else if(this.selectedComponentType == 'ENTITY_VIEW'){
        this.insertEntityView()
      } else {
        this.insertTableComponent();
        this.resetFormData();
      }
      this.getComponentStatus();
    },
    insertCalendarComponent() {
      this.customDashboardData.allTableComponents.push({
        component_name: this.calendarName,
        entity_id: this.selectedEntityData,
        filter_id: "",
        type: "CALENDAR",
        feature: "ENTITY",
        icon: "dashboard.svg",
        keyValue: this.getRandomKey(),
        order: this.customDashboardData.allTableComponents.length,
        selectedCalendarEntities: [
          ...this.selectedCalendarEntities,
          ...this.selectedCalendarRelationEntities,
        ],
        calenderDateField: this.calenderDateField,
        allowOtherShowData: this.allowOtherShowData,
        is_relational_data:
          this.selectedCalendarRelationEntities &&
          this.selectedCalendarRelationEntities.length
            ? true
            : false,
      });
      this.selectedCalendarRelationEntities = [];
      this.selectedCalendarEntities = [];
      this.calenderDateField = [];
    },
    insertEntityView(){
      let selectedView = this.getAllEntityViews.data.find(view => view._id == this.selectedEntityView)
      this.customDashboardData.allTableComponents.push({
        component_name : selectedView.name,
        entity_view_id : this.selectedEntityView,
        icon: "dashboard.svg",
        type : 'ENTITY_VIEW',
        feature : 'ENTITY_VIEW',
        keyValue : this.getRandomKey(),
        order : this.customDashboardData.allTableComponents.length,
        isExpandTable : false
      })
      this.selectedEntityView = ''
    },
    insertChartComponent() {
      const field = this.getTemplateFields.find(
        (e) => e.key == this.pieChartField
      );
      let entity_id = "";
      this.selectedEntityRelationalData;
      if (this.isEntityDashboard && this.selectedEntityRelationalData) {
        entity_id = this.selectedEntityRelationalData;
      } else {
        entity_id = this.selectedEntityData;
      }
      this.customDashboardData.allTableComponents.push({
        component_name: field.label,
        entity_id: entity_id,
        filter_id: "",
        type: "CHART",
        chart_type: this.chartType,
        feature: "ENTITY",
        icon: "dashboard.svg",
        isExpandTable: false,
        pie_chart_field: this.pieChartField,
        pie_chart_field_options: this.pieChartFieldOptions,
        pie_chart_field_group_by: this.pieChartFieldGroupBy,
        pie_chart_field_group_by_selected_options:
          this.pieChartFieldGroupBySelectedOptions,
        include_chart_total: this.includeChartTotal,
        sum_fields: this.sum_fields,
        keyValue: this.getRandomKey(),
        order: this.customDashboardData.allTableComponents.length,
        is_relational_data:
          this.isEntityDashboard && this.selectedEntityRelationalData
            ? true
            : false,
        chart_selected_filter: this.chartSelectedFilter,
      });
      this.resetPieModel();
    },
    async insertLoginsComponent() {
      let entity_id = "";
      if (this.isEntityDashboard && this.selectedEntityRelationalData) {
        entity_id = this.selectedEntityRelationalData;
      } else {
        entity_id = this.selectedLoginEntity;
      }
      this.customDashboardData.allTableComponents.push({
        component_name: "LOGINS",
        type: "LOGINS",
        entity_id: entity_id,
        filter_id: "",
        feature: "ENTITY",
        icon: "dashboard.svg",
        keyValue: this.getRandomKey(),
        isExpandTable: false,
        order: this.customDashboardData.allTableComponents.length,
        selectedLoginEntity: this.selectedLoginEntity,
        start_button: this.start_button,
        end_button: this.end_button,
        loginsDataDetails: this.loginsDataDetails,
        selectedEntityRelationalData: this.selectedEntityRelationalData,
        is_relational_data:
          this.isEntityDashboard && this.selectedEntityRelationalData
            ? true
            : false,
        Days: this.Days,
        auto_logout: this.auto_logout,
        user_field: this.user_field,
      });
      this.selectedLoginEntity = "";
      this.start_button = "";
      this.end_button = "";
      this.loginsDataDetails = [];
      this.selectedEntityRelationalData = "";
      if (this.auto_logout) {
        let params = {
          entityId: entity_id,
        };
        await this.$store.dispatch("entities/callCronForAutoLogout", params);
      }
    },
    resetFormData() {
      this.selectedEntityData = "";
      this.sum_fields = [];
      this.pieChartField = "";
      this.pieChartFieldOptions = [];
      this.pieChartFieldGroupBy = "";
      this.pieChartFieldGroupBySelectedOptions = [];
      this.selectedEntityRelationalData = "";
    },
    resetPieModel() {
      this.pieChartField = "";
      this.pieChartFieldOptions = [];
      this.chartSelectedFilter = "";
    },
    insertFilterComponent() {
      // const fields = this.getTemplateFields.filter(
      //   e => this.customFilterField.indexOf(e.key) !== -1
      // );
      let existedCustomFilters =
        this.customDashboardData.allCustomFilters.filter(
          (e) => e.entity_id == this.selectedEntityData
        ) || [];
      if (existedCustomFilters && existedCustomFilters.length) {
        existedCustomFilters.forEach((e) => {
          if (this.customFilterField.indexOf(e.filter_field) === -1) {
            let removeIndex =
              this.customDashboardData.allCustomFilters.findIndex(
                (el) =>
                  el.entity_id == this.selectedEntityData &&
                  el.filter_field == e.filter_field
              );
            this.removeFilterComponent(removeIndex);
          }
        });
      }
      this.customFilterField.forEach((e) => {
        let existedComponent = this.customDashboardData.allCustomFilters.find(
          (j) => {
            if (j.entity_id == this.selectedEntityData && j.filter_field == e) {
              return j;
            }
          }
        );
        if (!existedComponent) {
          let fieldType = this.getTemplateFields.find(
            (field) => field.key === e
          );

          this.customDashboardData.allCustomFilters.push({
            component_name: "",
            entity_id:
              this.isEntityDashboard && this.selectedEntityRelationalData
                ? this.selectedEntityRelationalData
                : this.selectedEntityData,
            filter_id: "",
            type: "CUSTOM_FILTER",
            feature: "ENTITY",
            icon: "dashboard.svg",
            filter_field: e,
            filter_field_type: fieldType.inputType,
            filter_field_entity_id: fieldType.entity_id,
            selected_filter: "",
            keyValue: this.getRandomKey(),
            is_relational_data:
              this.isEntityDashboard && this.selectedEntityRelationalData
                ? true
                : false,
          });
        }
      });
      bus.$emit("apply-custom-filter", {
        reset: true,
      });
      // this.resetFilterModal();
    },
    resetFilterModal() {
      this.customFilterField = [];
    },
    removeFilterComponent(index) {
      this.customDashboardData.allCustomFilters.splice(index, 1);
    },
    insertCardComponent() {
      let existedEntityFilters =
        this.customDashboardData.allTableComponents.filter(
          (e) => e.entity_id == this.selectedEntityData
        ) || [];
      if (existedEntityFilters && existedEntityFilters.length) {
        existedEntityFilters.forEach((e) => {
          if (this.selectedFilter.indexOf(e.filter_id) === -1) {
            let removeIndex =
              this.customDashboardData.allTableComponents.findIndex(
                (el) =>
                  el.entity_id == this.selectedEntityData &&
                  el.filter_id == e.filter_id
              );
            this.deleteComponent(removeIndex);
          }
        });
      }
      if (this.selectedFilter && this.selectedFilter.length) {
        this.selectedFilter.forEach((filter) => {
          let existedComponent =
            this.customDashboardData.allTableComponents.find((j) => {
              if (
                j.entity_id == this.selectedEntityData &&
                j.filter_id == filter
              ) {
                return j;
              }
            });
          if (!existedComponent) {
            let selectedEntity = this.getAllEntitiesData.find(
              (e) => e._id == this.selectedEntityData
            );
            let selectedEntityFilter = this.filters.find(
              (e) => e._id == filter
            );
            let name = "";
            if (selectedEntity && selectedEntity.name) {
              name = selectedEntity.name;
              if (selectedEntityFilter && selectedEntityFilter.filter_name) {
                name = name + " " + selectedEntityFilter.filter_name;
              }
            }
            let components = {
              component_name: name,
              entity_id: this.selectedEntityData,
              filter_id: filter,
              type: "CARD",
              feature: "ENTITY",
              icon: "dashboard.svg",
              keyValue: this.getRandomKey(),
              order: this.customDashboardData.allTableComponents.length,
            };
            this.customDashboardData.allTableComponents.push(components);
          }
        });
      } else {
        let selectedEntity = this.getAllEntitiesData.find(
          (e) => e._id == this.selectedEntityData
        );
        let name = "";
        if (selectedEntity && selectedEntity.name) {
          name = selectedEntity.name;
        }
        let components = {
          component_name: name,
          entity_id: this.selectedEntityData,
          type: "CARD",
          feature: "ENTITY",
          icon: "dashboard.svg",
          keyValue: this.getRandomKey(),
          self_entity: true,
          order: this.customDashboardData.allTableComponents.length,
        };
        this.customDashboardData.allTableComponents.push(components);
      }
    },
    insertTableComponent() {
      let entity_id = "";
      let is_relational_data =
        this.isEntityDashboard && this.selectedEntityRelationalData
          ? true
          : false;
      this.selectedFilter = this.selectedFilter.filter((e) => e);
      if (this.isEntityDashboard && this.selectedEntityRelationalData) {
        entity_id = this.selectedEntityRelationalData;
        is_relational_data = true;
      } else {
        entity_id = this.selectedEntityData;
      }
      let existedEntityFilters =
        this.customDashboardData.allTableComponents.filter(
          (e) =>
            e.type == "TABLE" &&
            e.entity_id == entity_id &&
            ((!e.is_relational_data && !is_relational_data) ||
              is_relational_data == e.is_relational_data)
        ) || [];
      if (existedEntityFilters && existedEntityFilters.length) {
        existedEntityFilters.forEach((e) => {
          if (
            this.selectedFilter.length &&
            this.selectedFilter.indexOf(e.filter_id) === -1
          ) {
            let removeIndex =
              this.customDashboardData.allTableComponents.findIndex(
                (el) =>
                  el.type == "TABLE" &&
                  el.filter_id &&
                  el.entity_id == entity_id &&
                  el.filter_id == e.filter_id &&
                  ((!el.is_relational_data && !is_relational_data) ||
                    is_relational_data == el.is_relational_data)
              );
            if (removeIndex > -1) {
              this.deleteComponent(removeIndex);
            }
          }
        });
      }
      if (this.selectedFilter && this.selectedFilter.length) {
        this.selectedFilter.forEach((filter) => {
          let existedComponent =
            this.customDashboardData.allTableComponents.find((j) => {
              if (
                !j.table_field_group_by &&
                j.type == "TABLE" &&
                j.entity_id == entity_id &&
                j.filter_id == filter &&
                ((!j.is_relational_data && !is_relational_data) ||
                  is_relational_data == j.is_relational_data)
              ) {
                return j;
              }
            });
          if (!existedComponent || this.tableFieldGroupBy) {
            let selectedEntity = this.getAllEntitiesData.find(
              (e) => e._id == this.selectedEntityData
            );
            let selectedEntityFilter = this.filters.find(
              (e) => e._id == filter
            );
            let name = "";
            if (selectedEntity && selectedEntity.name) {
              name = selectedEntity.name;
              if (selectedEntityFilter && selectedEntityFilter.filter_name) {
                name = name + " " + selectedEntityFilter.filter_name;
              }
            }
            let components = {
              component_name: name,
              entity_id: entity_id,
              filter_id: filter,
              type: "TABLE",
              feature: "ENTITY",
              icon: "dashboard.svg",
              keyValue: this.getRandomKey(),
              isExpandTable:
                this.customDashboardData.allTableComponents.isExpandTable,
              order: this.customDashboardData.allTableComponents.length,
              is_relational_data:
                this.isEntityDashboard && this.selectedEntityRelationalData
                  ? true
                  : false,
              table_field_group_by: this.tableFieldGroupBy,
              table_field_sub_group_by: this.tableFieldSubGroupBy,
              table_field_group_by_fields: this.tableFieldGroupByFields,
              table_field_group_by_selected_options:
                this.tableFieldGroupBySelectedOptions,
            };
            this.customDashboardData.allTableComponents.push(components);
          }
        });
      } else {
        let selectedEntity = this.getAllEntitiesData.find(
          (e) => e._id == entity_id
        );
        let name = "";
        if (selectedEntity && selectedEntity.name && is_relational_data) {
          name = "My " + selectedEntity.name;
        }
        let components = {
          component_name: name,
          entity_id: entity_id,
          type: "TABLE",
          feature: "ENTITY",
          icon: "dashboard.svg",
          keyValue: this.getRandomKey(),
          self_entity: true,
          isExpandTable: false,
          order: this.customDashboardData.allTableComponents.length,
          is_relational_data: is_relational_data,
          table_field_group_by: this.tableFieldGroupBy,
          table_field_sub_group_by: this.tableFieldSubGroupBy,
          table_field_group_by_fields: this.tableFieldGroupByFields,
          table_field_group_by_selected_options:
            this.tableFieldGroupBySelectedOptions,
        };
        this.customDashboardData.allTableComponents.push(components);
      }
      this.resetTableComponentData();
    },
    resetTableComponentData() {
      this.tableFieldGroupBy = "";
      this.tableFieldSubGroupBy = "";
      this.tableFieldGroupBySelectedOptions = [];
      this.tableFieldGroupByFields = [
        {
          key: "count",
          name: "Count",
          type: "count",
          sub_group: false,
        },
      ];
      this.selectedEntityData = [];
    },
    getComponentInfo() {
      if (
        this.selectedComponentType == "CHART" ||
        this.selectedComponentType == "CALENDAR"
      ) {
        this.fetchTemplateFields(true);
      } else if (
        this.selectedComponentType == "CUSTOM_FILTER" ||
        this.selectedComponentType == "TABLE"
      ) {
        this.fetchTemplateFields(false);
      } else {
        this.fetchAllEntityFilters(this.selectedEntityData);
      }
    },
    fetchTemplateFields(isChart) {
      if (!this.selectedEntityData && !this.selectedEntityRelationalData) {
        return;
      }
      let id = this.selectedEntityData
        ? this.selectedEntityData
        : this.selectedEntityRelationalData;
      this.fetchAllEntityFilters(id);
      let entity = this.getAllEntitiesData.find((e) => e._id == id);
      if (!entity) {
        entity = this.getRelationshipEntities.find((e) => e._id == id);
      }
      if (isChart) {
        this.pieChartField = "";
      }
      const templateIds = (entity.templates || [])
        .filter((e) => !e.is_repeatable)
        .flatMap((e) => e.template_id);
      const entityTemplates = this.allTemplates.filter(
        (e) => templateIds.indexOf(e._id) !== -1
      );
      this.getTemplateFields = [];
      this.getTotalFields = [];
      entityTemplates.forEach((e) => {
        let data = JSON.parse(JSON.stringify(e));
        this.getTemplateFields = [
          ...this.getTemplateFields,
          ...this.getTemplateFieldsByType(
            data,
            isChart
              ? [
                  "SELECT",
                  "YES_OR_NO",
                  "MULTI_SELECT",
                  "ENTITY",
                  "DATE",
                  // "DATE_TIME",
                ]
              : ["SELECT", "ENTITY", "MULTI_SELECT"]
          ),
        ];

        this.getTotalFields = [
          ...this.getTotalFields,
          ...this.getTemplateFieldsByType(data, [
            "NUMBER",
            "CURRENCY",
            "FORMULA",
            "AGGREGATE_FUNCTION",
          ]),
        ];
      });
      this.customFilterField = this.customDashboardData.allCustomFilters
        .filter((e) => e.entity_id === this.selectedEntityData)
        .flatMap((e) => e.filter_field);
    },

    async fetchAllEntityFilters(entityId) {
      if (!entityId) {
        return;
      }
      this.chartFilters = [];
      this.loading = true;
      await this.$store.dispatch("filters/fetchAllEntityFilters", {
        entityId: entityId,
      });
      if (this.addStatsModal) {
        this.statEntityFilters = [];
        if (this.getAllEntityFilters) {
          this.statEntityFilters = [...this.getAllEntityFilters];
        }
      } else {
        this.filters = [];
        this.selectedFilter = [];
        if (this.getAllEntityFilters) {
          this.filters = this.getAllEntityFilters;
          let entity_id = "";
          let is_relational_data =
            this.isEntityDashboard && this.selectedEntityRelationalData
              ? true
              : false;
          this.selectedFilter = this.selectedFilter.filter((e) => e);
          if (this.isEntityDashboard && this.selectedEntityRelationalData) {
            entity_id = this.selectedEntityRelationalData;
          } else {
            entity_id = this.selectedEntityData;
          }
          let existedFilters =
            this.customDashboardData.allTableComponents.filter(
              (u) =>
                u.entity_id == entity_id &&
                u.is_relational_data == is_relational_data
            );
          this.selectedFilter =
            existedFilters && existedFilters.length
              ? existedFilters.map((e) => e.filter_id)
              : [];
        }
      }
      this.chartFilters = this.getAllEntityFilters;
      this.loading = false;
    },
    async getNecessaryInfo() {
      this.loading = true;
      this.loadingText = "Fetching dashboard data...";
      const query = { get_all: true };
      if (this.isEntityDashboard) {
        if (this.$route.query.dashboardId) {
          await this.$store.dispatch(
            "customDashboard/getDashboardById",
            this.$route.query.dashboardId
          );
        } else {
          this.$store.commit("customDashboard/setSingleDashboardData", null, {
            root: true,
          });
        }
        await Promise.all([
          this.$store.dispatch(
            "applicationUsersPermission/fetchPermissionsEntityById",
            { id: this.$route.params.entityId }
          ),
          this.$store.dispatch("entityRelationships/fetchEntityRelationships", {
            entity_id: this.$route.params.entityId,
          }),
        ]);
      } else {
        await this.$store.dispatch(
          "customDashboard/getDashboardById",
          this.$route.params.dashboardId
        );
      }
      // let params = { get_all: true, include_standard: true, populate_data: true };
      await Promise.all([
        this.$store.dispatch("entities/fetchEntities", {
          get_all: true,
        }),
        this.$store.dispatch("companyTemplates/getAllCompanyTemplates", {
          get_all: true,
          include_standard: true,
          populate_data: true,
        }),
        this.$store.dispatch("globalVariables/fetchGlobalVariables", query),
        this.$store.dispatch("entityViews/getAllEntityViews", {get_all : true})
      ]);
      if (this.getAllCompanyTemplatesData?.data) {
        this.allTemplates = this.getAllCompanyTemplatesData.data;
      }
      // let response = await axios.get("template/list", {
      //   params,
      // });
      // this.getAllCompanyTemplatesData = { ...response.data };
      if (this.getEntityPermission?._id) {
        this.setPermittedEntities();
      }
      if (this.getEntityRelationships && this.$route.params.entityId) {
        this.setRelationshipPermissions(this.$route.params.entityId);
      }
      // console.log("-------------------------------------------->",this.getSingleDashboardData);
      if (this.getSingleDashboardData) {
        this.setData(this.getSingleDashboardData);
      } else {
        if (this.isEntityDashboard && this.getAllEntities) {
          let selectedEntity = (this.getAllEntities.data || []).find(
            (e) => e._id == this.$route.params.entityId
          );
          if (selectedEntity?.name) {
            this.customDashboardData.name = selectedEntity.name + " Dashboard";
          }
        }
      }
      if (this.getAllGlobalVariables && this.getAllGlobalVariables.data) {
        this.globalVariablesData = [...this.getAllGlobalVariables.data];
      }
      this.loading = false;
    },
    setRelationshipPermissions(id) {
      this.getEntityRelationships.forEach((el) => {
        if (el?.child_entity_id !== id) {
          this.relationshipEntities.push(el.child_entity_id);
        } else if (this.selectedEntityId !== id) {
          this.relationshipEntities.push(el.parent_entity_id);
        }
      });
    },
    setPermittedEntities() {
      this.permittedEntities = [];
      this.permittedRelatedEntities = [];
      this.permittedNestedRelatedEntities = [];
      if (this.getEntityPermission?.other_entities) {
        Object.keys(this.getEntityPermission?.other_entities).forEach((e) => {
          if (
            this.getEntityPermission?.other_entities[e].permissions &&
            this.getEntityPermission?.other_entities[e].permissions.indexOf(
              "VIEW"
            ) != -1
          ) {
            this.permittedEntities.push(e);
          }
        });
      }
      if (this.getEntityPermission?.relational_entities) {
        Object.keys(this.getEntityPermission?.relational_entities).forEach(
          (e) => {
            if (
              this.getEntityPermission?.relational_entities[e].permissions &&
              this.getEntityPermission?.relational_entities[
                e
              ].permissions.indexOf("VIEW") != -1
            ) {
              this.permittedRelatedEntities.push(e);
            }
          }
        );
      }
      if (this.getEntityPermission?.nested_relational_entities) {
        Object.keys(
          this.getEntityPermission?.nested_relational_entities
        ).forEach((e) => {
          if (
            this.getEntityPermission?.nested_relational_entities[e]
              .permissions &&
            this.getEntityPermission?.nested_relational_entities[
              e
            ].permissions.indexOf("VIEW") != -1
          ) {
            this.permittedNestedRelatedEntities.push(e);
          }
        });
      }
    },
    setData(data) {
      this.customDashboardData.name = data.name;
      this.customDashboardData.includeGlobalDateFilter =
        data.include_global_date_filter;
      this.customDashboardData.allowUserChangeOrder =
        data.allow_user_change_order;
      this.customDashboardData.allCustomFilters = data.components
        .filter((e) => e.type == "CUSTOM_FILTER")
        .map((e) => {
          e.value = "";
          e.selected_filter = "";
          if (e.selected_date_picker) {
            e.selected_date_picker = "";
          }
          e.keyValue = this.getRandomKey();
          return e;
        });
      this.customDashboardData.allTableComponents = [];
      let allMainComponents = data.components
        .filter(
          (e) =>
            e.type == "TABLE" ||
            e.type == "CHART" ||
            e.type == "CALENDAR" ||
            e.type == "LOGINS" ||
            e.type == 'ENTITY_VIEW'
        )
        .map((e) => {
          e.customFilers = this.customDashboardData.allCustomFilters
            .filter((el) => el.entity_id === e.entity_id && el.selected_filter)
            .map((fl) => {
              fl.field = fl.filter_field;
              fl.value = fl.selected_filter;
              fl.operator = "=case-insensitive";
              fl.query_type = "AND";
              fl.data_type = "STRING";
              return fl;
            });
          e.keyValue = this.getRandomKey();
          return e;
        });
      allMainComponents = allMainComponents.filter((e) => {
        if (e.type == "TABLE" && (!e.entity_id || !e.entity_id.length)) {
          return false;
        }
        return true;
      });
      this.customDashboardData.allTableComponents = allMainComponents.sort(
        (a, b) => (a.order < b.order ? -1 : 1)
      );
      this.customDashboardData.allStatComponents = data.components
        .filter((e) => e.type == "STAT")
        .map((e) => {
          e.customFilers = this.customDashboardData.allCustomFilters
            .filter((el) => el.entity_id === e.entity_id && el.selected_filter)
            .map((fl) => {
              fl.field = fl.filter_field;
              fl.value = fl.selected_filter;
              fl.operator = "=case-insensitive";
              fl.query_type = "AND";
              fl.data_type = "STRING";
              return fl;
            });
          if (!e.keyValue) {
            e.keyValue = this.getRandomKey();
          }
          return e;
        });
      (this.customDashboardData.allTableComponents || []).forEach((el, i) => {
        if (el.type == "CALENDAR" && el.calenderDateField) {
          el.calenderDateField.forEach((field) => {
            this.getEntityFilters(field.field);
          });
        }
        if (el.is_expand) {
          this.expendedIndexs.push(i);
        }
      });
      this.rawData = JSON.parse(
        JSON.stringify({ ...this.customDashboardData })
      );
      // console.log("this.customDashboardData", JSON.stringify(this.customDashboardData).replace(JSON.stringify(this.rawData), ""))
    },
    searchFilters() {
      if (this.filterSearchString.length > 0) {
        const regex = new RegExp(this.filterSearchString, "i");
        const matchedFilters = (this.getAllEntityFilters || []).filter(
          ({ filter_name }) => filter_name.match(regex)
        );
        this.filters = [...[], ...matchedFilters];
      } else {
        this.filters = [...[], ...(this.getAllEntityFilters || [])];
      }
    },
    getComponentStatus() {
      this.$notify({
        title: "Success",
        message: "Component saved successfully",
        type: "success",
      });
    },
    goBack() {
      if (this.isEntityDashboard) {
       
          this.$router.push(
          `/ap-user/custom-dashboards/${this.$route.params.entityId}?name=${this.$route.query.name}`
        );
      
      } else {
        if(this.checkUrlType() == 'private'){
          this.$router.push(`/if/${this.getAuthenticatedSSOToken}private/custom/dashboard-list`)
        }
        else{
        this.$router.push({
          path: "/custom/dashboard-list",
        });
      }
      }
    },
  },
};
</script>
<style lang="scss">
.calendar-header {
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 10px;
  width: 100% !important;
}
.left-side-menu {
  height: 100vh;
  padding: 5px;
  border-right: 1px solid #f2f2f2;
  /* transform: translate3d(0px, 0, 0);
  position: fixed;
  box-sizing: border-box;
  left: 0;
  bottom: 0;
  top: 67.5px;
  z-index: 999;
  background: white;
  
  width: 270px;
  padding-top: 10px;
  margin-top: 50px;
  background-color: aqua;
  color:black;
  border: 2px solid blue;
  width: 270px;
  transform: translate3d(0px, 0, 0);
  position: fixed;
  box-sizing: border-box;
  left: 0;
  bottom: 0;
  top: 100px; */
}
.side-menu {
  padding-top: 10px;
  padding-left: 20px;
}
.row {
  padding: 20px;
  /* background: #7e1b56;
  color: #ffffff; */
  border: 2px solid #f2f2f2;
}
.checkboxOrder {
  display: inline-flex;
  flex-direction: column;
  /* padding: 0px !important; */
}
.nodata-stat-root {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  color: #9f9f9f;
  cursor: pointer;
  height: 60px;
  text-align: center;
}
.all-table-body {
  word-wrap: break-word;
}

.title-input {
  background-color: rgba(255, 255, 255, 0.35);
  color: #222222 !important;
  padding: 0 !important;
  border: none !important;
  font-size: 1.5em !important;
  box-shadow: none;
  align-items: stretch;
  font-weight: 500;
  letter-spacing: -0.5px;
}
.drag-grid {
  display: grid !important;
  grid-template-columns: 50% 50% !important;
  column-gap: 10px !important;
  row-gap: 1em !important;
  padding: 25px;
}
.wrapper-grid {
  // display: grid !important;
  // grid-template-columns: 50% 50% !important;
  // column-gap: 10px !important;
  // row-gap: 1em !important;
  // padding: 25px;
}
</style>
